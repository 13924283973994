var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-overview" },
    [
      _c("div"),
      _c(
        "div",
        { staticClass: "refresh-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.dataDialogVisible = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-question",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("统计说明")
            ]
          ),
          _c("div", { staticStyle: { margin: "0 20px" } }, [
            _vm._v("更新时间：" + _vm._s(_vm.time))
          ]),
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.refresh()
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-refresh",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("刷新")
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("订单数据统计")]),
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-right": "10px" } },
            [
              _c(
                "el-button",
                {
                  class: [_vm.getOrderChangeStyle(1)],
                  staticStyle: { height: "30px", "line-height": "0px" },
                  on: {
                    click: function($event) {
                      _vm.changeOrderCount(1)
                    }
                  }
                },
                [_vm._v("总")]
              ),
              _c(
                "el-button",
                {
                  class: [_vm.getOrderChangeStyle(2)],
                  staticStyle: { height: "30px", "line-height": "0px" },
                  on: {
                    click: function($event) {
                      _vm.changeOrderCount(2)
                    }
                  }
                },
                [_vm._v("今天")]
              ),
              _c(
                "el-button",
                {
                  class: [_vm.getOrderChangeStyle(3)],
                  staticStyle: { height: "30px", "line-height": "0px" },
                  on: {
                    click: function($event) {
                      _vm.changeOrderCount(3)
                    }
                  }
                },
                [_vm._v("昨天")]
              ),
              _c(
                "el-button",
                {
                  class: [_vm.getOrderChangeStyle(4)],
                  staticStyle: { height: "30px", "line-height": "0px" },
                  on: {
                    click: function($event) {
                      _vm.changeOrderCount(4)
                    }
                  }
                },
                [_vm._v("近7日")]
              ),
              _c(
                "el-button",
                {
                  class: [_vm.getOrderChangeStyle(5)],
                  staticStyle: { height: "30px", "line-height": "0px" },
                  on: {
                    click: function($event) {
                      _vm.changeOrderCount(5)
                    }
                  }
                },
                [_vm._v("近30日")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "content-second" }, [
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.priceTotal))
                ]),
                _c("div", [_vm._v("总营收")]),
                _c("div", { staticStyle: { color: "#888888" } }, [
                  _vm._v(_vm._s(_vm.tip1) + _vm._s(_vm.priceGrowthRate))
                ])
              ])
            ]),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.orderTotal))
                ]),
                _c("div", [_vm._v("总订单数")]),
                _c("div", { staticStyle: { color: "#888888" } }, [
                  _vm._v(_vm._s(_vm.tip2) + _vm._s(_vm.orderGrowthRate))
                ])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.practicalPriceTotal))
                ]),
                _c("div", [_vm._v("实际营收")]),
                _c("div", { staticStyle: { color: "#888888" } }, [
                  _vm._v(
                    _vm._s(_vm.tip3) + _vm._s(_vm.practicalPriceGrowthRate)
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.practicalOrderTotal))
                ]),
                _c("div", [_vm._v("实际订单数")]),
                _c("div", { staticStyle: { color: "#888888" } }, [
                  _vm._v(
                    _vm._s(_vm.tip4) + _vm._s(_vm.practicalOrderGrowthRate)
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.refundPriceTotal))
                ]),
                _c("div", [_vm._v("退款额")]),
                _c("div", { staticStyle: { color: "#888888" } }, [
                  _vm._v(_vm._s(_vm.tip5) + _vm._s(_vm.refundPriceGrowthRate))
                ])
              ])
            ]),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.refundOrderTotal))
                ]),
                _c("div", [_vm._v("退款订单数")]),
                _c("div", { staticStyle: { color: "#888888" } }, [
                  _vm._v(_vm._s(_vm.tip6) + _vm._s(_vm.refundOrderGrowthRate))
                ])
              ])
            ]),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.churnRate))
                ]),
                _c("div", [_vm._v("流失率")]),
                _c("div", { staticStyle: { color: "#888888" } }, [
                  _vm._v(_vm._s(_vm.tip7) + _vm._s(_vm.churnRateGrowthRate))
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("近30天订单单数统计")]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "order-num" }
            })
          ]
        ),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("近30天订单金额统计")]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "order-price" }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("门店销售额Top10")]),
            _c(
              "div",
              {
                staticStyle: { "text-align": "right", "margin-right": "10px" }
              },
              [
                _c(
                  "el-button",
                  {
                    class: [_vm.getGroupChangeStyle(1)],
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeGroup(1)
                      }
                    }
                  },
                  [_vm._v("上个月")]
                ),
                _c(
                  "el-button",
                  {
                    class: [_vm.getGroupChangeStyle(2)],
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeGroup(2)
                      }
                    }
                  },
                  [_vm._v("近一月")]
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "store-num" }
            })
          ]
        ),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("商品销售订单数Top10")
            ]),
            _c(
              "div",
              {
                staticStyle: { "text-align": "right", "margin-right": "10px" }
              },
              [
                _c(
                  "el-button",
                  {
                    class: [_vm.getShopChangeStyle(1)],
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeShop(1)
                      }
                    }
                  },
                  [_vm._v("上个月")]
                ),
                _c(
                  "el-button",
                  {
                    class: [_vm.getShopChangeStyle(2)],
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeShop(2)
                      }
                    }
                  },
                  [_vm._v("近一月")]
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "shop-num" }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("销售额前5门店日销售订单")
            ]),
            _c(
              "div",
              {
                staticStyle: { "text-align": "right", "margin-right": "10px" }
              },
              [
                _c(
                  "el-button",
                  {
                    class: [_vm.getSaleChangeStyle(1)],
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeSale(1)
                      }
                    }
                  },
                  [_vm._v("上个月")]
                ),
                _c(
                  "el-button",
                  {
                    class: [_vm.getSaleChangeStyle(2)],
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeSale(2)
                      }
                    }
                  },
                  [_vm._v("近一月")]
                )
              ],
              1
            ),
            _c("div", {
              style: { width: "1608px", height: "322px" },
              attrs: { id: "sale-order" }
            })
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "统计说明",
            top: "2vh",
            visible: _vm.dataDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dataDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "line-height": "25px", "margin-top": "-20px" } },
              [
                _c("div", { staticClass: "data-title" }, [
                  _vm._v("订单数据统计")
                ]),
                _vm._v(
                  "\n                支持按照时间范围统计以下数据，总（全部)，今天，昨天，近7天，近30天(默认)；"
                ),
                _c("br"),
                _vm._v(
                  "\n                总营收=云数平台易柜售货销售订单支付总金额（含退款订单金额）"
                ),
                _c("br"),
                _vm._v(
                  "\n                总订单数=云数平台易柜售货订单总数（支付成功订单含退款订单）"
                ),
                _c("br"),
                _vm._v(
                  "\n                实际营收=云数平台易柜售货订单总营收金额（支付成功订单不含退款订单金额）"
                ),
                _c("br"),
                _vm._v(
                  "\n                实际订单数=云数平台易柜售货支付成功无退款的售货订单；（支付成功订单不含）"
                ),
                _c("br"),
                _vm._v(
                  "\n                退款额=云数平台易柜售货订单总支付成功且退款成功的订单总金额；（线上退款总金额）"
                ),
                _c("br"),
                _vm._v(
                  "\n                退款订单数=退款或部分退款的订单总订单数；"
                ),
                _c("br"),
                _vm._v("\n                流失率=退款订单数/总订单数*100%"),
                _c("br"),
                _vm._v("\n                日增长：当日数据与前一日增长情况；"),
                _c("br"),
                _vm._v(
                  "\n                环比增长：当前周期与上一个周期的增长情况；"
                ),
                _c("br")
              ]
            ),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("近30天订单单数统计")
              ]),
              _vm._v(
                "\n                下单数：创建订单的总数，含已支付，未支付，取消支付等；"
              ),
              _c("br"),
              _vm._v(
                "\n                支付数：支付成功订单数，含已退款或申请退款的订单；"
              ),
              _c("br"),
              _vm._v("\n                退款数：退款订单和申请退款的订单；"),
              _c("br"),
              _vm._v("\n                用曲线图展示近30天订单数据；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("订单金额统计")
              ]),
              _vm._v(
                "\n                下单金额：创建订单总金额，含已支付，未支付，取消订单金额；"
              ),
              _c("br"),
              _vm._v(
                "\n                支付金额：支付成功订单金额，含支付成功申请退款的，已退款的金额；"
              ),
              _c("br"),
              _vm._v("\n                退款金额：退款金额和申请退款的金额；"),
              _c("br"),
              _vm._v("\n                用曲线图展示近30天订单金额数据；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("门店销售额Top10")
              ]),
              _vm._v(
                "\n                按门店销售额统计前10个门店的销售金额和订单数；"
              ),
              _c("br"),
              _vm._v(
                "\n                按照上个月统计和近一月两个时间范围进行统计；（默认展示近一月）"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("商品销售订单数Top10")
              ]),
              _vm._v(
                "\n                按照商品销量情况统计，商品销售量，销售件数统计（子订单/件数）；"
              ),
              _c("br"),
              _vm._v("\n                统计并展示商品销售订单前10的商品；"),
              _c("br"),
              _vm._v(
                "\n                按照上个月统计和近一月两个时间范围进行统计；（默认展示近一月）"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("销售额前5门店日销售订单数据")
              ]),
              _vm._v(
                "\n                按照近一月（默认）和上个月时间范围，统计出的门店销售额前5个门店，展示以上5个门店的每日销售订单情况；"
              ),
              _c("br"),
              _vm._v(
                "\n                销售订单：支付成功的订单；（含申请退款和退款订单）"
              ),
              _c("br"),
              _vm._v("\n                门店根据销售额动态切换；"),
              _c("br"),
              _vm._v("\n                每日订单数据以折线图形式展示；"),
              _c("br"),
              _vm._v(
                "\n                今日订单数自今日00:01开始至最近统计时间（可刷新实时更新数据）;"
              ),
              _c("br")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }