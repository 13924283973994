<template>
    <div class="data-overview">
        <div></div>
        <div class="refresh-box">
            <el-button type="text" class="refresh-btn" @click="dataDialogVisible=true"><i class="el-icon-question" style="margin-right:2px"></i>统计说明</el-button>
            <div style="margin:0 20px">更新时间：{{time}}</div>
            <el-button type="text" class="refresh-btn" @click="refresh()"><i class="el-icon-refresh" style="margin-right:2px"></i>刷新</el-button>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">订单数据统计</div>
                <div style="text-align:right;margin-right:10px">
                    <el-button :class="[ getOrderChangeStyle(1)]" style="height: 30px;line-height: 0px;" @click="changeOrderCount(1)">总</el-button>
                    <el-button :class="[ getOrderChangeStyle(2)]" style="height: 30px;line-height: 0px;" @click="changeOrderCount(2)">今天</el-button>
                    <el-button :class="[ getOrderChangeStyle(3)]" style="height: 30px;line-height: 0px;" @click="changeOrderCount(3)">昨天</el-button>
                    <el-button :class="[ getOrderChangeStyle(4)]" style="height: 30px;line-height: 0px;" @click="changeOrderCount(4)">近7日</el-button>
                    <el-button :class="[ getOrderChangeStyle(5)]" style="height: 30px;line-height: 0px;" @click="changeOrderCount(5)">近30日</el-button>
                </div>
                <div class="content-second">
                    <div class="module-box">
                        <div style="text-align:left">
                            <div style="font-size:30px;">{{priceTotal}}</div>
                            <div>总营收</div>
                            <div style="color:#888888">{{tip1}}{{priceGrowthRate}}</div>
                        </div>
                    </div>
                    <div class="module-box">
                        <div style="text-align:left">
                            <div style="font-size:30px;">{{orderTotal}}</div>
                            <div>总订单数</div>
                            <div style="color:#888888">{{tip2}}{{orderGrowthRate}}</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div style="text-align:left">
                            <div style="font-size:30px;">{{practicalPriceTotal}}</div>
                            <div>实际营收</div>
                            <div style="color:#888888">{{tip3}}{{practicalPriceGrowthRate}}</div>
                        </div>
                    </div>
                    <div class="module-box">
                        <div style="text-align:left">
                            <div style="font-size:30px;">{{practicalOrderTotal}}</div>
                            <div>实际订单数</div>
                            <div style="color:#888888">{{tip4}}{{practicalOrderGrowthRate}}</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div style="text-align:left">
                            <div style="font-size:30px;">{{refundPriceTotal}}</div>
                            <div>退款额</div>
                            <div style="color:#888888">{{tip5}}{{refundPriceGrowthRate}}</div>
                        </div>
                    </div>
                    <div class="module-box">
                        <div style="text-align:left">
                            <div style="font-size:30px;">{{refundOrderTotal}}</div>
                            <div>退款订单数</div>
                            <div style="color:#888888">{{tip6}}{{refundOrderGrowthRate}}</div>
                        </div>
                    </div>
                    <div class="module-box">
                        <div style="text-align:left">
                            <div style="font-size:30px;">{{churnRate}}</div>
                            <div>流失率</div>
                            <div style="color:#888888">{{tip7}}{{churnRateGrowthRate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">近30天订单单数统计</div>
                <div id="order-num" class="module-content" :style="{ width: '784px', height: '322px'}"></div> 
            </div>
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">近30天订单金额统计</div>
                <div id="order-price" class="module-content" :style="{ width: '784px', height: '322px'}"></div>  
            </div>
        </div>
        <div class="module">
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">门店销售额Top10</div>
                <div style="text-align:right;margin-right:10px">
                    <el-button :class="[ getGroupChangeStyle(1)]" style="height: 30px;line-height: 0px;" @click="changeGroup(1)">上个月</el-button>
                    <el-button :class="[ getGroupChangeStyle(2)]" style="height: 30px;line-height: 0px;" @click="changeGroup(2)">近一月</el-button>
                </div>
                <div id="store-num" class="module-content" :style="{ width: '784px', height: '322px'}"></div> 
            </div>
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">商品销售订单数Top10</div>
                <div style="text-align:right;margin-right:10px">
                    <el-button :class="[ getShopChangeStyle(1)]" style="height: 30px;line-height: 0px;" @click="changeShop(1)">上个月</el-button>
                    <el-button :class="[ getShopChangeStyle(2)]" style="height: 30px;line-height: 0px;" @click="changeShop(2)">近一月</el-button>
                </div>
                <div id="shop-num" class="module-content" :style="{ width: '784px', height: '322px'}"></div>  
            </div>
        </div>
        <div class="module">
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">销售额前5门店日销售订单</div>
                <div style="text-align:right;margin-right:10px">
                    <el-button :class="[ getSaleChangeStyle(1)]" style="height: 30px;line-height: 0px;" @click="changeSale(1)">上个月</el-button>
                    <el-button :class="[ getSaleChangeStyle(2)]" style="height: 30px;line-height: 0px;" @click="changeSale(2)">近一月</el-button>
                </div>
                <div id="sale-order" :style="{ width: '1608px', height: '322px'}"></div> 
            </div>
        </div>
        <el-dialog title="统计说明" top="2vh" :visible.sync="dataDialogVisible"  :close-on-click-modal="false">
            <div>
                <div style="line-height:25px;margin-top:-20px">
                    <div class="data-title">订单数据统计</div>
                    支持按照时间范围统计以下数据，总（全部)，今天，昨天，近7天，近30天(默认)；<br>
                    总营收=云数平台易柜售货销售订单支付总金额（含退款订单金额）<br>
                    总订单数=云数平台易柜售货订单总数（支付成功订单含退款订单）<br>
                    实际营收=云数平台易柜售货订单总营收金额（支付成功订单不含退款订单金额）<br>
                    实际订单数=云数平台易柜售货支付成功无退款的售货订单；（支付成功订单不含）<br>
                    退款额=云数平台易柜售货订单总支付成功且退款成功的订单总金额；（线上退款总金额）<br>
                    退款订单数=退款或部分退款的订单总订单数；<br>
                    流失率=退款订单数/总订单数*100%<br>
                    日增长：当日数据与前一日增长情况；<br>
                    环比增长：当前周期与上一个周期的增长情况；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">近30天订单单数统计</div>
                    下单数：创建订单的总数，含已支付，未支付，取消支付等；<br>
                    支付数：支付成功订单数，含已退款或申请退款的订单；<br>
                    退款数：退款订单和申请退款的订单；<br>
                    用曲线图展示近30天订单数据；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">订单金额统计</div>
                    下单金额：创建订单总金额，含已支付，未支付，取消订单金额；<br>
                    支付金额：支付成功订单金额，含支付成功申请退款的，已退款的金额；<br>
                    退款金额：退款金额和申请退款的金额；<br>
                    用曲线图展示近30天订单金额数据；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">门店销售额Top10</div>
                    按门店销售额统计前10个门店的销售金额和订单数；<br>
                    按照上个月统计和近一月两个时间范围进行统计；（默认展示近一月）<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">商品销售订单数Top10</div>
                    按照商品销量情况统计，商品销售量，销售件数统计（子订单/件数）；<br>
                    统计并展示商品销售订单前10的商品；<br>
                    按照上个月统计和近一月两个时间范围进行统计；（默认展示近一月）<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">销售额前5门店日销售订单数据</div>
                    按照近一月（默认）和上个月时间范围，统计出的门店销售额前5个门店，展示以上5个门店的每日销售订单情况；<br>
                    销售订单：支付成功的订单；（含申请退款和退款订单）<br>
                    门店根据销售额动态切换；<br>
                    每日订单数据以折线图形式展示；<br>
                    今日订单数自今日00:01开始至最近统计时间（可刷新实时更新数据）;<br>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
    directives: {
        'el-table-infinite-scroll': elTableInfiniteScroll
    },
    data() {
        return {
            time:'',
            tip1:'环比增长：',
            tip2:'环比增长：',
            tip3:'环比增长：',
            tip4:'环比增长：',
            tip5:'环比增长：',
            tip6:'环比增长：',
            tip7:'环比增长：',
            type: 5,
            priceTotal:'',
            orderTotal:'',
            practicalPriceTotal:'',
            practicalOrderTotal:'',
            refundPriceTotal:'',
            refundOrderTotal:'',
            churnRate:'',
            priceGrowthRate:'',
            orderGrowthRate:'',
            practicalPriceGrowthRate:'',
            practicalOrderGrowthRate:'',
            refundPriceGrowthRate:'',
            refundOrderGrowthRate:'',
            churnRateGrowthRate:'',
            orderNum:[],
            orderPrice:[],
            payNum:[],
            payPrice:[],
            returnNum:[],
            returnPrice:[],
            dateData:[],
            dataDialogVisible: false,
            groupName: [],
            groupOrder: [],
            groupPrice: [],
            groupType: 2,
            shopName: [],
            shopOrder: [],
            shopType: 2,
            saleType: 2,
            saleName: [],
            saleDateData: [],
            saleData1: [],
            saleData2: [],
            saleData3: [],
            saleData4: [],
            saleData5: [],
        }
    },
    activated() {
        this.time = this.getNowTime();
        this.tip1 = '环比增长：',
        this.tip2 = '环比增长：',
        this.tip3 = '环比增长：',
        this.tip4 = '环比增长：',
        this.tip5 = '环比增长：',
        this.tip6 = '环比增长：',
        this.tip7 = '环比增长：',
        this.type = 5;
        this.groupType = 2;
        this.shopType = 2;
        this.saleType = 2;
        this.getOrderData();
        this.getOrderCount();
        this.getStoreTop();
        this.getShopTop();
        this.getSaleTop();
    },
    mounted() {
    },
    methods: {
        changeOrderCount(val){
            if(val == 1){
                this.tip1 = "总订单金额";
                this.tip2 = "";
                this.tip3 = "减去退款的剩余营收";
                this.tip4 = "减去退款的订单数";
                this.tip5 = "";
                this.tip6 = "含部分退款订单";
                this.tip7 = "";
                this.type = 1;
            }else if(val == 2){
                this.tip1 = "日增长：";
                this.tip2 = "日增长：";
                this.tip3 = "日增长：";
                this.tip4 = "日增长：";
                this.tip5 = "日增长：";
                this.tip6 = "日增长：";
                this.tip7 = "日增长：";
                this.type = 2;
            }else if(val == 3){
                this.tip1 = "环比增长：";
                this.tip2 = "环比增长：";
                this.tip3 = "环比增长：";
                this.tip4 = "环比增长：";
                this.tip5 = "环比增长：";
                this.tip6 = "环比增长：";
                this.tip7 = "环比增长：";
                this.type = 3;
            }else if(val == 4){
                this.tip1 = "环比增长：";
                this.tip2 = "环比增长：";
                this.tip3 = "环比增长：";
                this.tip4 = "环比增长：";
                this.tip5 = "环比增长：";
                this.tip6 = "环比增长：";
                this.tip7 = "环比增长：";
                this.type = 4;
            }else if(val == 5){
                this.tip1 = "环比增长：";
                this.tip2 = "环比增长：";
                this.tip3 = "环比增长：";
                this.tip4 = "环比增长：";
                this.tip5 = "环比增长：";
                this.tip6 = "环比增长：";
                this.tip7 = "环比增长：";
                this.type = 5;
            }
            this.getOrderData();
        },
        getOrderChangeStyle(type){
            return this.type === type ? 'active' : '';
        },
        changeGroup(val){
            if(val == 1){
                this.groupType = 1
            }else{
                this.groupType = 2
            }
            this.getStoreTop()
        },
        getGroupChangeStyle(type){
            return this.groupType === type ? 'active' : '';
        },
        changeShop(val){
            if(val == 1){
                this.shopType = 1
            }else{
                this.shopType = 2
            }
            this.getShopTop()
        },
        getShopChangeStyle(type){
            return this.shopType === type ? 'active' : '';
        },
        changeSale(val){
            if(val == 1){
                this.saleType = 1
            }else{
                this.saleType = 2
            }
            this.getSaleTop()
        },
        getSaleChangeStyle(type){
            return this.saleType === type ? 'active' : '';
        },
        getOrderData(){
            let dto = {
                type: this.type
            }
            this.post("statistic-service/v1/order/statistic/overview",dto,{
                isUseResponse: true,
            }).then(res=>{
                if(res){
                    this.priceTotal = res.data.data.priceTotal.toFixed(2);
                    this.orderTotal = res.data.data.orderTotal;
                    this.practicalPriceTotal = res.data.data.practicalPriceTotal.toFixed(2);
                    this.practicalOrderTotal = res.data.data.practicalOrderTotal;
                    this.refundPriceTotal = res.data.data.refundPriceTotal.toFixed(2);
                    this.refundOrderTotal = res.data.data.refundOrderTotal;
                    this.churnRate = res.data.data.churnRate+'%';
                    this.priceGrowthRate = this.type!=1?res.data.data.priceGrowthRate+'%':'';
                    this.orderGrowthRate = this.type!=1?res.data.data.orderGrowthRate+'%':'';
                    this.practicalPriceGrowthRate = this.type!=1?res.data.data.practicalPriceGrowthRate+'%':'';
                    this.practicalOrderGrowthRate = this.type!=1?res.data.data.practicalOrderGrowthRate+'%':'';
                    this.refundPriceGrowthRate = this.type!=1?res.data.data.refundPriceGrowthRate+'%':'';
                    this.refundOrderGrowthRate = this.type!=1?res.data.data.refundOrderGrowthRate+'%':'';
                    this.churnRateGrowthRate = this.type!=1?res.data.data.churnRateGrowthRate+'%':'';
                }
            })
        },
        getOrderCount(){
            this.post("statistic-service/v1/order/statistic/num-price").then(res=>{
                if(res){
                    let orderNum = []
                    let orderPrice = []
                    let payNum = []
                    let payPrice = []
                    let returnNum = []
                    let returnPrice = []
                    let dateData = []
                    res.listMap.forEach(item =>{
                        dateData.push(item.date);
                        orderNum.push(item.orderTotal);
                        orderPrice.push(item.priceTotal.toFixed(2));
                    });
                    res.payListMap.forEach(item =>{
                        payNum.push(item.orderTotal);
                        payPrice.push(item.priceTotal.toFixed(2));
                    });
                    res.refundListMap.forEach(item =>{
                        returnNum.push(item.orderTotal);
                        returnPrice.push(item.priceTotal.toFixed(2));
                    });
                    this.orderNum = orderNum;
                    this.orderPrice = orderPrice;
                    this.payNum = payNum;
                    this.payPrice = payPrice;
                    this.returnNum = returnNum;
                    this.returnPrice = returnPrice;
                    this.dateData = dateData;
                    this.creatNumEchart();
                    this.creatPrice()
                }
            })
        },
        getStoreTop(){
            let dto = {
                type: this.groupType
            }
            this.post("statistic-service/v1/order/statistic/group-top",dto,{
                isUseResponse: true,
            }).then(res=>{
                if(res){
                    let groupName = [];
                    let groupOrder = [];
                    let groupPrice = [];
                    res.data.data.forEach(item => {
                        groupName.push(item.groupName);
                        groupOrder.push(item.orderTotal);
                        groupPrice.push(item.priceTotal.toFixed(2));
                    })
                    this.groupName = groupName;
                    this.groupOrder = groupOrder;
                    this.groupPrice = groupPrice;
                    this.creatStore();

                }
            })
        },
        getShopTop(){
            let dto = {
                type: this.shopType
            }
            this.post("statistic-service/v1/order/statistic/items-top",dto,{
                isUseResponse: true,
            }).then(res=>{
                if(res){
                    let shopName = [];
                    let shopOrder = [];
                    res.data.data.forEach(item => {
                        shopName.push(item.itemName);
                        shopOrder.push(item.orderTotal);
                    })
                    this.shopName = shopName;
                    this.shopOrder = shopOrder;
                    this.creatShop();

                }
            })
        },
        getSaleTop(){
            let dto = {
                type: this.saleType
            }
            this.post("statistic-service/v1/order/statistic/group-top-five",dto,{
                isUseResponse: true,
            }).then(res=>{
                if(res){
                    let saleName = [];
                    res.data.data.forEach(item => {
                        saleName.push(item[0].groupName);
                        let saleDateData = [];
                        item.forEach(val => {
                            saleDateData.push(val.date)
                        })
                        this.saleDateData = saleDateData;
                    })
                    this.saleName = saleName;
                    let saleData1 = [];
                    let saleData2 = [];
                    let saleData3 = [];
                    let saleData4 = [];
                    let saleData5 = [];
                    for(let i = 1; i <= res.data.data.length; i++){
                        res.data.data[i-1].forEach(item =>{
                            if(i==1){
                                saleData1.push(item.orderTotal)
                            }else if(i==2){
                                saleData2.push(item.orderTotal)
                            }else if(i==3){
                                saleData3.push(item.orderTotal)
                            }else if(i==4){
                                saleData4.push(item.orderTotal)
                            }else if(i==5){
                                saleData5.push(item.orderTotal)
                            }
                        })
                    }
                    this.saleData1 = saleData1;
                    this.saleData2 = saleData2;
                    this.saleData3 = saleData3;
                    this.saleData4 = saleData4;
                    this.saleData5 = saleData5;
                    this.creatSaleOreder();
                }
            })
        },
        getNowTime(){
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear() // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
            var week = weekArr[date.getDay()];
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return year + "/" + month + "/" + day + " " + hour + sign2 + minutes + sign2 + seconds;
        },
        refresh(){
            this.time = this.getNowTime();
            this.tip1 = '环比增长：',
            this.tip2 = '环比增长：',
            this.tip3 = '环比增长：',
            this.tip4 = '环比增长：',
            this.tip5 = '环比增长：',
            this.tip6 = '环比增长：',
            this.tip7 = '环比增长：',
            this.type = 5;
            this.groupType = 2;
            this.shopType = 2;
            this.saleType = 2;
            this.getOrderData();
            this.getOrderCount();
            this.getStoreTop();
            this.getShopTop();
            this.getSaleTop();
        },
        creatNumEchart(){
            const chartDom = document.getElementById('order-num')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['下单数', '支付数', '退款数']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.dateData,
                    axisLabel: {
                        interval: 3  //设置 X 轴数据间隔几个显示一个，为0表示都显示 
                    }, 
                },
                yAxis: {
                    type: 'value'
                },
                color: ['#31B9FF','#16EA76','#FF6262'],
                series: [
                    {
                        name: '下单数',
                        type: 'line',
                        data: this.orderNum,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: '支付数',
                        type: 'line',
                        data: this.payNum,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: '退款数',
                        type: 'line',
                        data: this.returnNum,
                        smooth: 0.5, // 设置折线弧度
                    },
                ]
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatPrice(){
            const chartDom = document.getElementById('order-price')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['下单金额', '支付金额', '退款金额']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.dateData,
                    axisLabel: {
                        interval: 3  //设置 X 轴数据间隔几个显示一个，为0表示都显示 
                    }, 
                },
                yAxis: {
                    type: 'value'
                },
                color: ['#314EFF','#A7EA16','#FFA71D'],
                series: [
                    {
                        name: '下单金额',
                        type: 'line',
                        data: this.orderPrice,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: '支付金额',
                        type: 'line',
                        data: this.payPrice,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: '退款金额',
                        type: 'line',
                        data: this.returnPrice,
                        smooth: 0.5, // 设置折线弧度
                    },
                ]
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatStore(){
            const chartDom = document.getElementById('store-num')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                //图表位置
                grid: {
                    left: "3%",
                    // right: "5%",
                    bottom: "3%",
                    top:'15px',
                    containLabel: true,
                },
                //X轴
                xAxis: {
                    type: "value",
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //不显示X轴刻度线和数字
                    splitLine: { show: false },
                    axisLabel: { show: false },
                },
                yAxis: {
                    type: "category",
                    data: this.groupName,
                    //升序
                    inverse: true,
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //key和图间距
                    offset: 10,
                    //key文字大小
                    nameTextStyle: {
                        fontSize: 16,
                    },
                    axisLabel: {
                        show: true,
                        formatter: function(value) {
                            var res = value;
                            // 长度超过4个的以省略号显示
                            if(res.length > 8) {
                                res = res.substring(0, 8) + "..";
                            }
                            return res;
                        }
                    },
                },
                series: [
                {
                    //柱状图自动排序，排序自动让Y轴名字跟着数据动
                    realtimeSort: false,
                    name: "销售额",
                    type: "bar",
                    data: this.groupPrice,
                    barWidth: 16,
                    barGap: 20,
                    smooth: false,
                    valueAnimation: false,
                    //Y轴数字显示部分
                    label: {
                        normal: {
                            show: true,
                            position: "right",
                            valueAnimation: false,
                            offset: [5, 0],
                            textStyle: {
                                color: "#333",
                                fontSize: 14,
                            },
                        },
                    },
                    itemStyle: {
                        emphasis: {
                            barBorderRadius: 7,
                        },
                        //颜色样式部分
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            { offset: 1, color: '#B2FBFF' },
                            { offset: 0, color: '#0CBFC8' }
                        ]),
                            barBorderRadius: 2,
                        },
                        
                    },
                },
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatShop(){
            const chartDom = document.getElementById('shop-num')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                //图表位置
                grid: {
                    left: "3%",
                    // right: "4%",
                    bottom: "3%",
                    top:'15px',
                    containLabel: true,
                },
                //X轴
                xAxis: {
                    type: "value",
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //不显示X轴刻度线和数字
                    splitLine: { show: false },
                    axisLabel: { show: false },
                },
                yAxis: {
                    type: "category",
                    data: this.shopName,
                    //升序
                    inverse: true,
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //key和图间距
                    offset: 10,
                    //key文字大小
                    nameTextStyle: {
                        fontSize: 16,
                    },
                    axisLabel: {
                        show: true,
                        formatter: function(value) {
                            var res = value;
                            // 长度超过4个的以省略号显示
                            if(res.length > 8) {
                                res = res.substring(0, 8) + "..";
                            }
                            return res;
                        }
                    },
                },
                series: [
                {
                    //柱状图自动排序，排序自动让Y轴名字跟着数据动
                    realtimeSort: false,
                    name: "订单数",
                    type: "bar",
                    data: this.shopOrder,
                    barWidth: 16,
                    barGap: 20,
                    smooth: false,
                    valueAnimation: false,
                    //Y轴数字显示部分
                    label: {
                        normal: {
                            show: true,
                            position: "right",
                            valueAnimation: false,
                            offset: [5, 0],
                            textStyle: {
                                color: "#333",
                                fontSize: 14,
                            },
                        },
                    },
                    itemStyle: {
                        emphasis: {
                            barBorderRadius: 7,
                        },
                        //颜色样式部分
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            { offset: 1, color: '#FFF6C6' },
                            { offset: 0, color: '#FFB016' }
                        ]),
                            barBorderRadius: 2,
                        },
                        
                    },
                },
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatSaleOreder(){
            const chartDom = document.getElementById('sale-order')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: this.saleName
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: this.saleDateData,
                    axisLabel: {
                        interval: 1  //设置 X 轴数据间隔几个显示一个，为0表示都显示 
                    }, 
                },
                yAxis: {
                    type: 'value'
                },
                // color: ['#314EFF','#A7EA16','#FFA71D'],
                series: [
                    {
                        name: this.saleName[0],
                        type: 'line',
                        data: this.saleData1,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: this.saleName[1],
                        type: 'line',
                        data: this.saleData2,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: this.saleName[2],
                        type: 'line',
                        data: this.saleData3,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: this.saleName[3],
                        type: 'line',
                        data: this.saleData4,
                        smooth: 0.5, // 设置折线弧度
                    },
                    {
                        name: this.saleName[4],
                        type: 'line',
                        data: this.saleData5,
                        smooth: 0.5, // 设置折线弧度
                    },
                ]
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        }
    }
}
</script>
<style lang="scss" scope>
.data-overview{
    background: #F6F6F6;
    margin: -10px -20px;
    padding: 35px;
    font-size: 14px;
    overflow: auto;
    .module{
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    .refresh-box{
        height: 16px;
        line-height: 16px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-right: 20px;
    }
    .refresh-btn{
        color: #333;
        padding: 0;
        margin-left: 3px;
    }
    .title{
        border-left: 4px solid #30BAC1;
        height: 16px;
        line-height: 16px;
        padding-left: 7px;
        background: #FFFFFF;
        font-size: 16px;
    }
    .content{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
    }
    .content-second{
        background: #FFFFFF;
        width: 1608px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
    .line{
        background: #DCDFE6;
        width: 1px;
        height: 93px;
    }
    .module-box{
        display: flex; 
        img{
            width: 56px;
            height: 56px;
            margin-right: 15px;
        }
    }
    .circleBox {
        position: relative;
        text-align: center;
        width: 200px;
    }

    .circleCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .module-content {
        padding: 10px 0 0 10px;
        background: #FFFFFF;
    }
    .data-title {
        font-weight: bold;
        margin: 10px 0 0;
    }
    .el-table__row>td{ border: none; }
    .el-table th.is-leaf { border-bottom: none; }
    .el-table::before { height: 0px; } 
    .table-content{
        overflow: auto;
        background: #FFFFFF;
        width: 794px;
        margin: 10px 0 30px;
        height: '400px';
    }
    .count-content2{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
    .module-count{
        color: #fff;
        width: 210px;
        height: 82px;
        padding: 10px 15px;
        border-radius: 10px;
        display: flex; 
        justify-content: space-between;
    }
    .room1{
        background-image: linear-gradient(to right , #5671FC, #C97DFE);
    }
    .room2{
        background-image: linear-gradient(to right , #38A8F6, #767CFC);
    }
    .room3{
        background-image: linear-gradient(to right , #F6950B, #FFEB3E);
    }
    .room4{
        background-image: linear-gradient(to right , #E95E74, #F09F61);
    }
    .room5{
        background-image: linear-gradient(to right , #38A8F6, #767CFC);
    }
    .room6{
        background-image: linear-gradient(to right , #48D898, #9EE04E);
    }
    .spread-top{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .spread-length{
        height: 10px;
        background: #76DC71;
        border-radius: 20px;
    }
    .active{
        color: #30BAC1;
        border-color: #30BAC1;
    }
}
</style>